import SpotifyPlayer from 'react-spotify-player'
import React from 'react'

// size may also be a plain string using the presets 'large' or 'compact'
const size = {
  // width: '100%',
  height: 400
}
const view = 'list' // or 'coverart'
const theme = 'black' // or 'white'

const CustomSpotifyPlayer = ({ uri }) => (
  <SpotifyPlayer uri={uri} size={size} view={view} theme={theme} />
)

export default CustomSpotifyPlayer
