import React from 'react'
import BandcampPlayer from 'react-bandcamp'
// <iframe
//   style="border: 0; width: 400px; height: 406px;"
//   src={`https://bandcamp.com/EmbeddedPlayer/album=${id}/size=large/bgcol=333333/linkcol=ffffff/artwork=small/transparent=true/`}
//   seamless
// >
//   <a href="http://peluzah.bandcamp.com/album/and-then-what">And then, what? by PeluzaH</a>
// </iframe>
const CustomBandCampPlayer = ({ id }) => (
  <div>
    <BandcampPlayer
      album={id}
      size="large"
      bgcol="333333"
      linkcol="ffffff"
      artwork="small"
      transparent="true"
      tracklist="true"
      height="400px"
    />
  </div>
)

export default CustomBandCampPlayer
