import React from 'react'
import { graphql } from 'gatsby'
import BlockContent from '../components/block-content'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
// import PeopleGrid from '../components/people-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import localize from '../components/localize'
import LayoutContainer from '../components/layout-container'
import SanityTextBlockEmbed from '../components/body/SanityTextBlockEmbed'
// import Hero from '../components/body/Hero'
import VideoListing from '../components/youtube/youtube'
import { responsiveTitle1 } from '../components/typography.module.css'

import PreviewGrid from '../components/lists-preview/preview-grid'
import { cn, buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import CustomSpotifyPlayer from '../components/players/spotify-player'
import CustomBandCampPlayer from '../components/players/bandcamp-player'
import styles from '../components/album.module.css'

export const query = graphql`
  query AlbumTemplateQuery($id: String!) {
    album: sanityAlbum(id: { eq: $id }) {
      id
      slug {
        current
        _type
      }
      mainImage {
        alt
        asset {
          _id
        }
      }
      title
      releaseYear(formatString: "YYYY")
      bandcamp
      bandcampAlbum
      songList
      spotifyUri
      spotify
      _rawBody
    }
  }
`

const AlbumTemplate = ({ data, errors, pageContext }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const album = data && data.album

  // console.log(album, 'album')
  const locale = pageContext.locale

  if (!album) {
    throw new Error(
      'Missing "Album" pages. Open the studio at http://localhost:3333 and add "Album" page data and restart the development server.'
    )
  }
  // const songList = album.songList.map((song) => <li>{song}</li>)
  // size may also be a plain string using the presets 'large' or 'compact'

  return (
    <LayoutContainer locale={locale}>
      <SEO title={album.title} lang={locale} />
      <Container className={styles.root}>
        <h1 className={responsiveTitle1}>{album.title}</h1>
        {/* {album.releaseYear && <h4>{album.releaseYear}</h4>} */}
        <div className={styles.albumInfo}>
          <div className={styles.left}>
            <div className={styles.body}>
              <BlockContent blocks={album._rawBody || []} />
            </div>
            {/* {album.songList && <ol>{songList}</ol>} */}
            {album.spotifyUri && (
              <CustomSpotifyPlayer uri={album.spotifyUri} className={styles.player} />
            )}
            {album.bandcampAlbum && (
              <CustomBandCampPlayer id={album.bandcampAlbum} className={styles.player} />
            )}
          </div>
          <div className={styles.right}>
            {album.mainImage && album.mainImage.asset && (
              <img
                className={styles.img}
                src={imageUrlFor(buildImageObj(album.mainImage))
                  .width(300)
                  // .height(Math.floor((9 / 16) * 600))
                  .url()}
                alt={album.mainImage.alt}
              />
            )}

            <ul className={styles.platformLinks}>
              {album.bandcamp && (
                <li>
                  <a href={album.bandcamp} target="_blank">
                    BandCamp
                  </a>
                </li>
              )}
              {album.spotify && (
                <li>
                  <a href={album.spotify} target="_blank">
                    Spotify
                  </a>
                </li>
              )}
            </ul>
          </div>
          {/* {album.bandcampAlbum && <CustomBandCampPlayer id={album.bandcampAlbum} />}
          <br /> */}
        </div>
        {/* <div class="player">
          {album.spotifyUri && <CustomSpotifyPlayer uri={album.spotifyUri} />}
        </div> */}
      </Container>
    </LayoutContainer>
  )
}

export default localize(AlbumTemplate)
